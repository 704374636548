import React from 'react'
import './navbar.css'
import {AiOutlineHome, AiOutlineUser, AiOutlineContacts} from 'react-icons/ai'
import {IoCodeWorkingSharp} from 'react-icons/io5'
import { useState } from 'react'

const Navbar = () => {
  const [activeNav, setActiveNav] = useState('#')
  return (
    <nav>
      <a href="#home" onClick={() => setActiveNav('#home')} className={activeNav === '#' ? 'active' : ''}><AiOutlineHome/><span>Home</span></a>
      <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}><AiOutlineUser/><span>About</span></a>
      <a href="#projects" onClick={() => setActiveNav('#projects')} className={activeNav === '#projects' ? 'active' : ''}><IoCodeWorkingSharp/><span>Projects</span></a>
      <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><AiOutlineContacts/><span>Contact Me</span></a>
    </nav>
  )
}

export default Navbar

import React from 'react'
import {BsFacebook, BsGithub, BsInstagram, BsLinkedin, BsMailbox, BsTwitter} from 'react-icons/bs'

const Social = () => {
    return (
        <div className='socials'>
            <a href="https://www.linkedin.com/in/surajgiri7/" target={'_blank'} rel="noopener noreferrer"> <BsLinkedin/> </a>
            <a href="https://github.com/surajgiri7" target={'_blank'} rel="noopener noreferrer"> <BsGithub/> </a>
            <a href="https://twitter.com/_girisuraj_" target={'_blank'} rel="noopener noreferrer"> <BsTwitter/> </a>
            <a href="https://www.instagram.com/_g.i.r.i.s.u.r.a.j_/" target={'_blank'} rel="noopener noreferrer"> <BsInstagram/> </a>
            <a href="https://www.facebook.com/1G.I.R.I.S.U.R.A.J1" target={'_blank'} rel="noopener noreferrer"> <BsFacebook/> </a>
            <a href="#contact" target={'_blank'} rel="noopener noreferrer"> <BsMailbox/> </a>
        </div >
    )
}

export default Social